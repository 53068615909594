import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import "./nav.scss";
import { motion } from "framer-motion";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { isMobile } from "react-device-detect";
import logo from "./logo.svg";

export default () => {
  const [ham, changeHam] = useState(isMobile ? false : true);

  const activestyle = {
    color: "#FECF2A",
  };
  const resetHam = () => {
    if (isMobile) {
      changeHam(!ham);
    }
  };
  let navlink = {
    hidden: {
      opacity: 0,
      y: "-100vh",
    },
    show: {
      opacity: 1,
      y: "0",
    },
  };

  let navIcon = {
    hidden: {
      scale: 0,
    },
    show: {
      scale: 1,
    },
  };

  const navbar = {
    hidden: {
      rotateY: 112,
    },
    show: {
      rotateY: 0,
    },
  };

  useEffect(() => {
    if (isMobile) {
      setTimeout(() => {
        changeHam(true);
        changeHam(false);
      }, 1000);
    }
  }, []);
  return (
    <nav>
      <div className="brand">
        <div className="logo">
          <Link to="/">
            {/* <Img fluid={data.file.childImageSharp.fluid} /> */}
            <img src={logo} alt="" srcset="" width="180" />
          </Link>
        </div>
      </div>
      <div
        className={ham ? "ham ham-open" : "ham"}
        onClick={() => changeHam(!ham)}
      >
        <motion.span
          animate={{ opacity: 1, x: 0, y: 0, color: "white" }}
          initial="false"
        />
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
      <motion.div
        animate={ham ? "show" : "hidden"}
        initial={ham ? "hidden" : "show"}
        variants={navbar}
        transition={{ duration: 0.1, ease: "easeInOut" }}
        className="navbar"
      >
        <div className="navlinks">
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.5 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <span />
            <Link activeStyle={activestyle} to="/">
              HOME
            </Link>
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.6 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <span />
            <Link activeStyle={activestyle} to="/products">
              PRODUCTS
            </Link>
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.7 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <Link activeStyle={activestyle} to="/about-us">
              ABOUT US
            </Link>
            <span />
          </motion.div>
          <motion.div
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navlink}
            transition={{ delay: 0.8 }}
            className="navlink"
            onClick={() => resetHam()}
          >
            <span />
            <Link activeStyle={activestyle} to="/contact-us">
              CONTACT US
            </Link>
          </motion.div>
        </div>
        <ul className="nav-icons">
          <motion.a
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navIcon}
            transition={{ delay: 1.2 }}
            href="https://www.facebook.com/ocsointernational/"
          >
            <FaFacebookF />
          </motion.a>
          <motion.a
            animate={ham ? "show" : "hidden"}
            initial={ham ? "hidden" : "show"}
            variants={navIcon}
            transition={{ delay: 1.3 }}
            href="https://www.instagram.com/ocso_international/"
          >
            <FaInstagram />
          </motion.a>
        
        </ul>
      </motion.div>
    </nav>
  );
};
