import React from "react";
import { Link } from "gatsby";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaWhatsapp,
} from "react-icons/fa";
import "./footer.scss";

export default () => {
  return (
    <>
      <a className="whatsapp">
        <a href="https://wa.me/919925050564" target="_blank">
          <FaWhatsapp size="3.3rem" />
        </a>
      </a>
      <footer>
        <div className="footer container">
          <div className="footer-logo">
            <Link to="/">OCSO</Link>
          </div>

          <div className="footer-up">
            <div>
              <p>
                3/4 New Jagnath Plot, <br />
                Yagnik Road, <br />
                Rajkot
              </p>
            </div>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="blog">Blog</Link>
              </li>
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="contact-us">Contact Us</Link>
              </li>
           
            </ul>
            <div>
              <h1>Any questions?</h1>
              <a href="tel:09925050564">+91 9925050564</a>

              <a href="mailto:support@ocso.in">support@ocso.in</a>

              <div className="footer-icons">
                <a href="https://www.facebook.com/ocsointernational/">
                  <FaFacebookF />
                </a>
                <a href="https://www.instagram.com/ocso_international/">
                  <FaInstagram />
                </a>
              </div>
            </div>
          </div>
          <div className="footer-copy">
            2020 OCSO INTERNATIONAL. All Rights Reserved.
          </div>
        </div>
      </footer>
    </>
  );
};
